.kgb-advance-search-drawer .ant-drawer-content-wrapper {
	width: 320px;
}
.kgb-advance-search-drawer .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body .ant-select {
	width: 100%;
}
.kgb-advance-search-drawer .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body .ant-col {
	margin-bottom: 10px;
}
.kgb-advance-search-drawer .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body .ant-calendar-picker {
	width: 100%;
}
.ant-modal-content .ant-modal-header .ant-modal-title {
	color: #788195;
}
.ant-modal-content .ant-modal-footer .kgb-modal-btn-cancel {
	text-decoration: underline;
	margin-right: 30px;
	cursor: pointer;
	font-weight: 500;
}
.kgb-advance-search-drawer .drawer-clearall {
	text-align: right;
	color: #4482ff;
	text-decoration: underline;
	cursor: pointer;
}
.kgb-top-notify #notify-symbol {
	width: 22px;
	height: 22px;
	top: -10px;
	background-color: #de350b;
}
.main-loading {
	min-height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ContentWrapperParent {
	width: 100%;
}

.report-module.ant-modal {
	top: 20px !important;
}

.modal-add-subscription {
	top: 10px !important;
}

.modal-add-subscription .ant-table-content {
	max-height: 510px;
	border-radius: 12px;
}

.editable-cell {
	position: relative;
}

.editable-cell-value-wrap {
	padding: 5px 12px;
	cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
	padding: 4px 11px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
	border: 1px solid #434343;
}

.hasError.ant-input {
	border: '1px solid #FFFF' !important ;
}

.ant-upload.ant-upload-select-picture-card {
	margin-right: 0 !important;
}
